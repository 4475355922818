export default class ModalWindow {
  constructor (el, targetSelector) {
    this.el = el
    this.modal = document.querySelector(targetSelector)

    if (this.modal) {
      this.closeBtn = this.modal.querySelector('.btn-close')
      this.modalBg = this.modal.querySelector('.rs-modal-bg')

      this.initEvents()
    }
  }

  initEvents() {
    // Click events
    this.el.addEventListener('click', event => {
      event.preventDefault()
      this.openWindow()
    })

    this.closeBtn.addEventListener('click', () => {
      this.closeWindow()
    })

    this.modalBg.addEventListener('click', () => {
      this.closeWindow()
    })
  }

  openWindow() {
    const iframe = this.modal.querySelector('iframe')
    if (iframe) {
      const id = iframe.getAttribute('id'); // or iframe.id
      const src = iframe.getAttribute('src'); // or iframe.src
      const youtubeID = id.split("-")[2];
      if (id.includes("youtube-player") && src == "") {
        iframe.setAttribute("src", "https://www.youtube.com/embed/"+youtubeID)
      }
    }
    this.modal.classList.remove('!hidden')
    document.body.classList.add('overflow-hidden')
  }

  closeWindow() {
    const iframe = this.modal.querySelector('iframe')
    if (iframe) {
      const id = iframe.getAttribute('id'); // or iframe.id
      if (id.includes("youtube-player")) {
        iframe.setAttribute("src", "")
      }
    }
    this.modal.classList.add('!hidden')
    document.body.classList.remove('overflow-hidden')
  }
}

let swiper = null;
function initializeSwiper() {
  // Destroy existing Swiper instance if it exists
  if (swiper) swiper.destroy();
  
  // Reinitialize Swiper
  swiper = new Swiper('.swiper', {
    direction: 'horizontal',
    loop: true,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.next-question',
      prevEl: '.next-question',
    },
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });
}


document.addEventListener('turbolinks:load', function() {
  initializeSwiper();
  var modals           = document.querySelectorAll("[data-modal]")
  var modalOpeners     = document.querySelectorAll("[data-modal-open]")
  var modalClosers     = document.querySelectorAll("[data-modal-close]")

  modals.forEach((modal) => {
    if (modal.hasAttribute("data-modal-disable-cancel")) {
      modal.addEventListener('cancel', (event) => {
        event.preventDefault();
      });
    }
  })

  modalOpeners.forEach((modalOpener) => {
    modalOpener.addEventListener("click", (e) => {
      var modalId = e.target.getAttribute("data-modal-id");
      var modal = document.getElementById(modalId)
      modal.showModal();
    })
  })

  modalClosers.forEach((modalCloser) => {
    modalCloser.addEventListener("click", () => {
      document.querySelectorAll("dialog").forEach((dialog) => {
        dialog.close();
      })
    })
  })
});
